import React, { useState, useEffect, setState } from 'react'
import QuoteInfo  from './components/quote-info'
import QuoteItem  from './components/quote-item'
import CalendarIcon from './calendar.png'
import moment from 'moment'
import axios from 'axios'
import 'antd/dist/antd.css'
import dayjs from 'dayjs';
import { DatePicker, TimePicker } from 'antd'

const PageQuote = ({ company, quote }) => {
  const [selectedQuote, setSelectedQuote] = useState(null)
  //(quote.earliest_delivery);
  //console.log(quote.quotes.sort((a, b) => (parseInt(a.price) > parseInt(b.price)) ? 1 : -1))

  const [errors, setErrors] = useState({
    collection: {
      date: false,
      from: false, 
      to: false,
      weekend: false, 
      out_of_hours: false,
      invalid: false
    },
    delivery: {
      date: false,
      from: false,
      to: false,
      weekend: false, 
      out_of_hours: false,
      invalid: false
    }
  })

  const [collection, setCollection] = useState({
    date: "",
    time_range: "AT",
    from: "",
    to: ""
  })

  const [delivery, setDelivery] = useState({
    date: "",
    time_range: "ASAP",
    from: "",
    to: ""
  })

  useEffect(() => {
    timesValidation()
  }, [collection.date, collection.time_range, collection.from, collection.to, delivery.date, delivery.time_range, delivery.from, delivery.to])

  const timesValidation = () => {
    var today = moment().format("YYYY-MM-DD")
    var dayStart = moment(`${today} 07:00`)
    var dayEnd = moment(`${today} 18:00`)

    let err = {
      collection: { date: false, from: false, to: false, weekend: false, out_of_hours: false, invalid: false },
      delivery: { date: false, from: false, to: false, weekend: false, out_of_hours: false, invalid: false }
    }

    // validate collection from & to
    let colDate = moment(collection.date)

    if (colDate.day() == 0 || colDate.day() == 6) {
      err.collection.date = true
      err.collection.weekend = true
    } else {
      err.collection.date = false
      err.collection.weekend = false
    }

    if (colDate.isBefore(today)) {
      err.collection.date = true
      err.collection.invalid = true
    }

    switch(collection.time_range) {
      case "BETWEEN": {
        let from = moment(`${today} ${collection.from}`)
        let to = moment(`${today} ${collection.to}`)

        if (from.isBefore(dayStart) || from.isAfter(dayEnd)) {
          err.collection.from = true
          err.collection.out_of_hours = true
        } 

        if (to.isBefore(dayStart) || to.isAfter(dayEnd)) {
          err.collection.to = true
          err.collection.out_of_hours = true
        } 

        if (to.isBefore(from)) {
          err.collection.to = true
          err.collection.invalid = true
        }

        if (from.isAfter(to)) {
          err.collection.from = true
          err.collection.invalid = true
        }

        break;
      }
      case "AT": {
        let from = moment(`${today} ${collection.from}`)

        if (from.isBefore(dayStart) || from.isAfter(dayEnd)) {
          err.collection.from = true
          err.collection.out_of_hours = true
        }

        break;
      }
      case "BEFORE": {
        let to = moment(`${today} ${collection.to}`)

        if (to.isBefore(dayStart) || to.isAfter(dayEnd)) {
          err.collection.to = true
          err.collection.out_of_hours = true
        }

        break;
      }
    }

    // validate delivery from & to
    let delDate = moment(delivery.date)

    if (delDate.day() == 0 || delDate.day() == 6) {
      err.delivery.date = true
      err.delivery.weekend = true
    }

    if (delDate.isBefore(today)) {
      err.delivery.date = true
      err.delivery.invalid = true
    }

    if (delDate.isBefore(colDate)) {
      err.delivery.date = true
      err.collection.date = true
      err.delivery.invalid = true
    }

    switch(delivery.time_range) {
      case "BETWEEN": {
        let from = moment(`${today} ${delivery.from}`)
        let to = moment(`${today} ${delivery.to}`)

        if (from.isBefore(dayStart) || from.isAfter(dayEnd)) {
          err.delivery.from = true
          err.delivery.out_of_hours = true
        }

        if (to.isBefore(dayStart) || to.isAfter(dayEnd)) {
          err.delivery.to = true
          err.delivery.out_of_hours = true
        }

        if (to.isBefore(from)) {
          err.delivery.to = true
          err.delivery.invalid = true
        }

        if (from.isAfter(to)) {
          err.delivery.from = true
          err.delivery.invalid = true
        }

        break;
      }
      case "AT": {
        let from = moment(`${today} ${delivery.from}`)

        if (from.isBefore(dayStart) || from.isAfter(dayEnd)) {
          err.delivery.from = true
          err.delivery.out_of_hours = true
        }

        break;
      }
      case "BY": {
        let to = moment(`${today} ${delivery.to}`)

        if (to.isBefore(dayStart) || to.isAfter(dayEnd)) {
          err.delivery.to = true
          err.delivery.out_of_hours = true
        }

        break;
      }
    }

    setErrors({
      collection: err.collection,
      delivery: err.delivery
    })


  }

  const invalidTimes = () => {
    return errors.collection.invalid || errors.delivery.invalid
  }

  useEffect(() => {
    const { booking: { pickups, dropoffs  } } = quote
    const pickup = pickups[0]
    const dropoff = dropoffs[dropoffs.length - 1]

    setCollection({
      date: moment(pickup.ready_at).format("YYYY-MM-DD"),
      time_range: pickup.time_range,
      from: moment(pickup.ready_at).format("HH:mm"),
      to: moment(pickup.collect_by).format("HH:mm")
    })

    setDelivery({
      date: moment(dropoff.deliver_from).format("YYYY-MM-DD"),
      time_range: dropoff.time_range,
      from: moment(dropoff.deliver_from).format("HH:mm"),
      to: moment(dropoff.deliver_by).format("HH:mm")
    })
  }, [])


  const onQuoteSelected = (quote) => {
    setSelectedQuote(quote)
  }

  const disabledDate = (current) => {
    console.log("Getting Disabled Date");
    console.log(quote.earliest_pickup);
    console.log(moment(quote.earliest_pickup, 'YYYY-MM-DD'));
    console.log(current < dayjs().endOf("day").subtract(1, 'day'));
    return current < dayjs().endOf("day").subtract(1, 'day');
  };
  
  const now = moment();
  const disabledTime = () => ({
    disabledHours: () => range(0, now.hour()),

  });

  const updateQuote = () => {

    let pickups = [...quote.booking.pickups]
    let dropoffs = [...quote.booking.dropoffs]

    pickups[0].time_range = collection.time_range
    pickups[0].ready_at = moment(`${collection.date} ${collection.from}`).format('YYYY-MM-DD HH:mm:ss')
    pickups[0].collect_by = moment(`${collection.date} ${collection.to}`).format('YYYY-MM-DD HH:mm:ss')

    dropoffs[dropoffs.length -1].time_range = delivery.time_range
    dropoffs[dropoffs.length -1].deliver_from = moment(`${delivery.date} ${delivery.from}`).format('YYYY-MM-DD HH:mm:ss')
    dropoffs[dropoffs.length -1].deliver_by = moment(`${delivery.date} ${delivery.to}`).format('YYYY-MM-DD HH:mm:ss')

    axios.put(`/quote/quotes/${quote.id}`, {
      quote: {
        ...quote,
        quote: selectedQuote,
        booking: {
          ...quote.booking,
          details: {
            ...quote.booking.details,
            quote: selectedQuote.ref
          },
          pickups,
          dropoffs
        }
      }
    }).then((response) => {
      window.location.assign(`/quote/step-3?quote=${quote.id}`)
    })
  }

  useEffect(() => {
    if(selectedQuote) {
      updateQuote()
    }
  }, [selectedQuote])

  return (
    <React.Fragment>
      <QuoteInfo quote={quote} company={company} />
      
      { (errors.collection.out_of_hours || errors.collection.weekend || errors.delivery.out_of_hours || errors.delivery.weekend) && (
        <div className="custom-progress-container mt-6">
          <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 my-4">
            <p className="font-bold">Out of hours</p>
            <p>The selected times for pick up or delivery are classed as out of hours or a weekend, this is no problem but an additional fee will be applied. If you prefer not to pay the additional out of hours fee please arrange your collection time Monday to Friday after 7am and delivery before 6pm . Any question please feel free to call us.</p>
          </div>
        </div>  
      ) }
      

      <section className="custom-progress-container mt-6 custom-collection-delivery lg:flex">
        
        <form action="" className="lg:flex w-full">
            <div className="flex-col lg:flex-row flex-1 lg:w-1/2 md:w-full mx-1">
                <label className="font-semibold" htmlFor="">Collection:</label>
                <div className="flex-1 md:w-full">
                    <div className="collection md:flex relative">

                        { collection.date && (
                          <DatePicker 
                            value={moment(collection.date, 'YYYY-MM-DD')}
                            allowClear={false}
                            disabledDate={disabledDate}
                            className={`${errors.collection.date ? 'error' : ''} input-bg-gray-2 date-input w-full md:w-2/5 bg-transparent border border-l-0 border-t-0 border-b-0 border-white focus:outline-none h-12 text-gray-500 px-4`} 
                            onChange={(date, dateString) => {
                                setCollection({
                                  ...collection,
                                  date: dateString
                                })
                                setDelivery({
                                  ...delivery,
                                  date: dateString
                                })
                            }}
                          />
                        )}
                        
                        
                        <div className="flex w-full">
                            
                            <select 
                              value={collection.time_range} 
                              onChange={(e) => {
                                setCollection({
                                  ...collection,
                                  time_range: e.target.value 
                                })
                              }} 
                              className="input-bg-gray-2 px-4 bg-transparent focus:outline-none h-12 text-gray-500 flex-1"
                              style={{ border: '1px solid #d9d9d9' }}
                            >
                                <option value="BETWEEN">Between</option>
                                <option value="BEFORE">Before</option>
                                <option value="AT">At</option>                        
                            </select>  
                          
                            
                            { collection.time_range != "BEFORE" && (
                                <>
                                  { collection.from && (
                                    <TimePicker 
                                      defaultValue={moment(collection.from, 'HH:mm')}  
                                      value={moment(collection.from, 'HH:mm')}  
                                      format={"HH:mm"}
                                      onChange={(t) => {
                                        setCollection({
                                          ...collection, 
                                          from: t.format("HH:mm")
                                        })
                                      }}
                                      onSelect={(t) => {
                                        setCollection({
                                          ...collection, 
                                          from: t.format("HH:mm")
                                        })
                                      }}
                                      allowClear={false}
                                      
                                      className={`${errors.collection.from ? 'error' : ''} input-time input-bg-gray-2 px-4 bg-transparent focus:outline-none h-12 text-gray-500 flex-1 border border-l-0 border-t-0 border-b-0 border-white`}
                                    />
                                  )}
                                </>
                            )}
                            
                            { collection.time_range != "AT" && (
                              <>
                                <TimePicker 
                                      defaultValue={moment(collection.to, 'HH:mm')}  
                                      value={moment(collection.to, 'HH:mm')}
                                      format={"HH:mm"}
                                      onChange={(t) => {
                                        setCollection({
                                          ...collection, 
                                          to: t.format("HH:mm")
                                        })
                                      }}
                                      onSelect={(t) => {
                                        setCollection({
                                          ...collection, 
                                          to: t.format("HH:mm")
                                        })
                                      }}
                                      
                                      allowClear={false}
                                      
                                      className={`${errors.collection.to ? 'error' : ''} input-time input-bg-gray-2 px-4 bg-transparent focus:outline-none h-12 text-gray-500 flex-1 border border-l-0 border-t-0 border-b-0 border-white`}
                                    />
                              </>

                              
                            )}
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-col md:flex-row flex-1 lg:w-1/2 md:w-full mx-1 mt-5 md:mt-0">
                <label className="font-semibold" htmlFor="">Delivery:</label>
                <div className="flex-1 md:w-full">
                    <div className="delivery md:flex relative">

                        { delivery.date && (
                          <DatePicker 
                            value={moment(delivery.date, 'YYYY-MM-DD')}
                            allowClear={false}
                            className={`${errors.delivery.date ? 'error' : ''} input-bg-gray-2 date-input w-full md:w-2/5 bg-transparent border border-l-0 border-t-0 border-b-0 border-white focus:outline-none h-12 text-gray-500 px-4`} 
                            onChange={(date, dateString) => {
                                setDelivery({
                                  ...delivery,
                                  date: dateString
                                })
                            }}
                          />
                        )}

                        <div className="flex w-full">
                            <select 
                                value={delivery.time_range} 
                                className="input-bg-gray-2 px-4 bg-transparent focus:outline-none h-12 text-gray-500 flex-1"
                                onChange={(e) => {
                                  setDelivery({
                                    ...delivery,
                                    time_range: e.target.value
                                  })
                                }}
                                style={{ border: '1px solid #d9d9d9' }}
                              >
                                <option value="BETWEEN">Between</option>
                                <option value="ASAP">ASAP</option>
                                <option value="AT">At</option>                        
                                <option value="BY">By</option>
                            </select>  
                            
                            { delivery.time_range != "ASAP" && delivery.time_range != "BY" && (
                              <>
                                <TimePicker 
                                    defaultValue={moment(delivery.from, 'HH:mm')} 
                                    value={moment(delivery.from, 'HH:mm')} 
                                    format={"HH:mm"}
                                    onChange={(t) => {
                                      setDelivery({
                                        ...delivery, 
                                        from: t.format("HH:mm")
                                      })
                                    }}
                                    onSelect={(t) => {
                                      setDelivery({
                                        ...delivery, 
                                        from: t.format("HH:mm")
                                      })
                                    }}
                                    allowClear={false}
                                    
                                    className={`${errors.delivery.from ? 'error' : ''} input-time input-bg-gray-2 px-4 bg-transparent focus:outline-none h-12 text-gray-500 flex-1 border border-l-0 border-t-0 border-b-0 border-white`}
                                  />
                              </> 
                            ) }

                            
                            { delivery.time_range != "ASAP" && delivery.time_range != "AT" && (
                              <>
                                <TimePicker 
                                    defaultValue={moment(delivery.to, 'HH:mm')}  
                                    value={moment(delivery.to, 'HH:mm')} 
                                    format={"HH:mm"}
                                    onChange={(t) => {
                                      setDelivery({
                                        ...delivery, 
                                        to: t.format("HH:mm")
                                      })
                                    }}
                                    onSelect={(t) => {
                                      setDelivery({
                                        ...delivery, 
                                        to: t.format("HH:mm")
                                      })
                                    }}
                                    allowClear={false}
                                    
                                    className={`${errors.delivery.to ? 'error' : ''} input-time input-bg-gray-2 px-4 bg-transparent focus:outline-none h-12 text-gray-500 flex-1 border border-l-0 border-t-0 border-b-0 border-white`}
                                  />
                              </> 
                            )}

                        </div>

                    </div>
                </div>
            </div>
        </form>
      </section>

      <div className="mx-auto custom-container-progress-result mt-10 sm:flex custom-progress-container">
        { quote.quotes.sort((a, b) => (parseInt(a.price) > parseInt(b.price)) ? 1 : -1).map((q) => {
          return (<QuoteItem key={q.ref} quote={q} selectQuote={onQuoteSelected} error={invalidTimes()} />)
        }) }
      </div>

    </React.Fragment>
  )
}

export default PageQuote;